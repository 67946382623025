import React, { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        background: "url('bg.jpg') no-repeat center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed", // Ensures background stays in place
        display: "flex",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1
          style={{
            color: "#2196f3",
            fontSize: 56,
            fontFamily: "Arial",
            textShadow: `-2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white`,
            lineHeight: 1,
          }}
        >
          Little Nomad-M kids
        </h1>
        <h2
          style={{
            color: "#2196f3",
            fontSize: 16,
            fontFamily: "Arial",
            lineHeight: 1,
            textTransform: "lowercase",
          }}
        >
          Хүүхдийг сэтгүүл
        </h2>

        <div style={{ display: "flex", justifyContent: "center", gap: "20px", paddingTop: "40px" }}>
          <img
            src="mobile.png"
            alt="Download on the App Store"
            style={{
              width: "272px",
              height: "536px",
            }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center", gap: "20px", paddingTop: "50px", paddingBottom: "100px" }}>
          <a href="https://apps.apple.com/us/app/littlenomad-magazine/id6738403526" target="_blank" rel="noopener noreferrer">
            <img src="app-store-badge.png" alt="Download on the App Store" width="269" height="80" />
          </a>
          {/* <a href="https://play.google.com/store/apps/details?id=com.littlenomadmagazine.littlenomad" target="_blank" rel="noopener noreferrer">
            <img src="google-play-badge.png" alt="Get it on Google Play" width="269" height="80" />
          </a> */}
        </div>

        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "40px",
            fontSize: "8px",
            color: "white",
          }}
        >
          © 2024 “ЛИТТЛЕ НОМАД МАГАЗИН“ ХХК
        </span>
      </div>
    </div>
  );
};

export default Home;
