import React from "react";

const TermsPage = () => {
  return (
    <div
      style={{
        background: "url('bg_stars.jpg') no-repeat center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed", // Ensures background stays in place
        display: "flex",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ textAlign: "", marginTop: "50px" }}>
        <div style={{ maxWidth: "600px", padding: "10px 20px 10px 20px" }}>
          <p style={{ textAlign: "center" }}>
            <span style={{ fontWeight: 400 }}>Эцэг эх болон асран хамгаалагчдад зориулагдсан болно.</span>
          </p>
          <h1>
            <span style={{ fontWeight: 400 }}>Үйлчилгээний нөхцөл</span>
          </h1>

          <p>
            <span style={{ fontWeight: 400 }}>
              " ЛИТТЛЕ НОМАД МАГАЗИН " ХХК нь / цаашид " компани &rdquo; гэх / монгол улсад үүсгэн байгуулагдаж, үйл ажиллагаа явуулдаг хуулийн этгээд
              бөгөөд стрийминг үйлчилгээ / цаашид "үйлчилгээ&rdquo; гэх / үзүүлэх холбоотойгоор энэхүү үйлчилгээний нөхцөлийг / цаашид "нөхцөл гэх /
              хэрэглэгчтэй байгуулсан " цахим гэрээ &rdquo; гэж үзнэ.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Little nomad-M kids платформ нь " ЛИТТЛЕ НОМАД МАГАЗИН " ХХК-ийн өмч бөгөөд платформыг ашиглахаас өмнө хэрэглэгч та доорх нөхцөлүүдийг
              уншиж танилцан төлбөрөө төлснөөр энэхүү нөхцөлийг зөвшөөрсөнд тооцон, үйлчилгээг ашиглах боломж албан ёсоор нээгдэнэ. Хэрэв энэхүү
              нөхцөлтэй танилцан хүлээн зөвшөөрөөгүй болон төлбөр төлөгдөөгүй тохиолдолд үйлчилгээг ашиглахаас татгалзсанд тооцон, үйлчилгээг цааш
              ашиглах боломжгүй болохыг анхаарна уу.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Нэг. Ерөнхий нөхцөл</span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                "Little nomad-M kids платформ" / цаашид "Little nomad-M kids&rdquo; гэх/ нь олон төхөөрөмжид суурилсан аппликейшн / цаашид "Апп&rdquo;
                гэх /, контент нийтлэх платформыг хэлнэ.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Little nomad-M kids аппликэйшн нь дотроо 15 үндсэн цэстэй бөгөөд тус бүр цэсүүд тэс дарааллын дагуу 80-100 хувь бэлэн болсноор
                хэрэглэгчдэд мэдэгдэж хүрэх болно.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Энэхүү апп үйлчилгээ нь бага дунд нас буюу 6-12 насны хүүхдэд зориулагдсан тул эцэг эх хяналт хийх шаардлага байхгүй бөгөөд олон олон
                хяналтаар баталгаажсаны улмаар Апп- т байршиж байгаа.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Оюуны өмч, зохиогчийн эрхийн хуулийн хүрээнд контент бүрийн гарах эрх газарзүйн байрлалаас хамааран харилцан адилгүй байна.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Хэрэглэгч гэж платформд утасны дугаараараа бүртгэл хийн апп " аа ашиглаж буй хэрэглэгчийг хэлнэ.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                " Little nomad-M kids эрх " гэж хагас жил бүр тогтмол үнээр мэргэжлийн багш нарын шигшмэл контентуудыг хэрэглэгчдэд олгохыг үйлчилгээ
                гэнэ.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                " Little nomad-M kids эрх "- гэж дангаар нь тус бүрийн үнээр олон жилийн туршлагатай мэргэжлийн багш, баг хамт олны хичээл зүтгэлийн
                батлагдсан үр дүнг харуулсан контентыг тодорхой хугацааны турш үзэх үйлчилгээг хэлнэ.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Little nomad-M kids аппликейшны үндсэн 15 цэс 2025 оны 11 сарын 30- ны өдөр 100 хувь бэлэн болж дууссан байна. Энэхүү нэг жилийн
                хугацаанд сар бүр хэрэглэгч хүүхдүүддээ шинэ мэдээлэл, контентыг хүргэж байх болно.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Таталт: " Little nomad-M kids эрхийн хүчинтэй хугацаанд аппликейшн багцад хамаарах контентыг татсанаар интернэт холболтгүй үед хаанаас
                ч, хэзээ ч үзэх боломжтой.
              </span>
            </li>
          </ul>

          <p>
            <span style={{ fontWeight: 400 }}>Хоёр: Бүртгэл</span>
          </p>

          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Хэрэглэгч утасны дугаараар бүртгэл үүсгэх боломжтой.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Хэрэглэгч бүртгэл үүсгэхдээ үүсгэхдээ үнэн зөв байдлыг хариуцах бөгөөд худал мэдээлэл өгсөн тохиолдолд " Little nomad-M kids "
                хариуцлага хүлээхгүй.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Худал мэдээлэл өгөх нь хэрэглэгчид хүрч үйлчлэхэд доголдол үүсэх, техникийн тусламж үйлчилгээ удаашрах, аливаа хүсэлтийг шийдвэрлэхэд
                хүндрэл гарах зэрэг эрсдэл дагуулах боломжтой.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Бүртгэл үүсгэхэд ашигласан хэрэглэгчийн хувийн мэдээллийг Little nomad-M kids нууцлалын бодлого дагуу хамгаална.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Хэрэглэгч өөрийн мэдээллийг задруулахгүй байх үүрэгтэй бөгөөд хэрэглэгчийн буруутай үйл ажиллагаанаас үүсэх эрсдэлийг " Little nomad-M
                kids " хариуцахгүй.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Хэрэглэгч бүртгэлийн үнэн зөв, аюулгүй байдал алдагдсан гэж үзэж байвал нэн даруй бидэнд мэдэгдэнэ үү.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Хэрэглэгч бүртгэлтэй утасны дугаараа зөв оруулснаар төрсөн өдөр болон бусад дурсгалтай өдрүүдэд Апп-ын гэрээтэй байгууллагуудын
                урамшуулалд хамрагдах бүрэн боломжтой.
              </span>
            </li>
          </ul>

          <p>
            <span style={{ fontWeight: 400 }}>Гурав: Төлбөр</span>
          </p>

          <ol>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Little nomad-M kids эрх нь үйлчилгээг идэвхжүүлж төлбөр амжилттай төлсөн үеэс эхлэн идэвхтэй байна.
              </span>
            </li>
          </ol>

          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Нэг утасны дугаар дээр бүртгэл үүсгэх эрх " 9,900 төгрөг</span>
            </li>
          </ul>

          <ol>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Little nomad-M kids эрх дуусахаас өмнө сунгалт хийсэн тохиолдолд өмнөх эрхийн дуусах хугацаа дээр нэмж сунгагдана.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Төлбөр амжилттай төлөгдөж Little nomad-M kids эрх нээгдсэн эсвэл сунгагдсан тохиолдолд буцаан олголт хийгдэхгүй.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Үнийн дүн зөрүүтэй гүйлгээ хийгдсэн тохиолдолд хэрэглэгч Little nomad magazine фейсбүүк пэйж хаягаар дамжуулан харилцагчийн
                үйлчилгээний ажилтанд мэдэгдэх бөгөөд тухайн зөрүүтэй гүйлгээг ажлын 3 хоногт багтаан шийдвэрлэнэ.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Төлбөр амжилттай төлөгдсөний дараа Little nomad-M kids эрх нээгдэхгүй, сунгагдахгүй байвал Little nomad magazine фейсбүүк пэйж хаягаар
                дамжуулан харилцагчийн үйлчилгээний ажилтанд мэдэгдэж нэн даруй шийдвэрлүүлнэ үү.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Хэрэглэгч дараах хэлбэрээр төлбөр төлөх боломжтой.</span>
            </li>
          </ol>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Qpay</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Hipay</span>
            </li>
          </ul>

          <p>
            <span style={{ fontWeight: 400 }}>Дөрөв. Хэрэглэгчийн эрх, үүрэг</span>
          </p>

          <ol>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Хэрэглэгч нь үйлчилгээний талаар санал, гомдол гаргах, шийдвэрлүүлэх эрхтэй.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Үйлчилгээг ашиглах нууц үгийг бусадтай хуваалцахгүй байх, нууцлал болон аюулгүй байдлыг бүрэн хариуцах үүрэгтэй.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Хэрэглэгч нууц үгээ алдсанаас болж үүсэх эрсдэлийг Little nomad-M kids хариуцахгүй болно.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Хэрэглэгч үйлчилгээг зүй бусаар / контентыг зүй бусаар хуулбарлан олшруулах, олон нийтэд түгээх, худалдаалах зэргээр / ашиглахгүй байх
                мөн бусад байдлаар гуравдагч этгээдэд үзэх боломж олгохгүй байх үүрэгтэй.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Хэрэглэгч үйлчилгээг зүй бусаар ашигласан тохиолдолд Little nomad-M kids бүртгэлийг цуцлахаас гадна хуулийн хариуцлага хүлээлгэх
                хүртэл шаардлагатай арга хэмжээ авч ажиллана.
              </span>
            </li>
          </ol>

          <p>
            <span style={{ fontWeight: 400 }}>Тав. Үйлчилгээ үзүүлэгчийн эрх, үүрэг</span>
          </p>

          <ol>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Бүтээгдэхүүн үйлчилгээ болон контентыг шинэчлэх өөрчлөх эрхтэй.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Хэрэглэгчдэд чанар стандартын өндөр түвшинд үйлчилгээ үзүүлэх үүрэгтэй.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Хэрэглэгчийн бүртгэлтэй хаяг болон аппликейшнээр дамжуулан төлбөр, урамшуулал, үйлчилгээтэй холбоотой мэдээлэл зэргийг хүргэх эрхтэй
                байна.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Гэнэтийн, урьдчилсан тооцоолох боломжгүй нөхцөл мөн давагдашгүй хүчин зүйлсийн улмаас үйлчилгээ 24 цагаас дээш хугацаанд тасалдаж,
                ашиглах боломжгүй болсон тохиолдолд хэрэглэгчийн эрх ашгийг нэн даруй хамгаалахын тулд тасалдсан тухайн хугацааг нэмэлтээр Little
                nomad-M kids эрхийн хоног олгох ажлыг зохион байгуулж ажиллана.
              </span>
            </li>
          </ol>

          <p>
            <span style={{ fontWeight: 400 }}>Зургаа. Бусад</span>
          </p>

          <ol>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Ашиглахад хялбар, таатай байлгах үүднээс Little nomad-M kids үйлчилгээний нөхцөл аппликейшны бүтэц, харагдах байдалд өөрчлөлт орж
                болно.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Үйлчилгээний нөхцөлтэй холбоотой асуулт гарвал харилцагчийн үйлчилгээний ажилтан болон </span>
              <a href="mailto:littlenomad2020@gmail.com">
                <span style={{ fontWeight: 400 }}>littlenomad2020@gmail.com</span>
              </a>
              <span style={{ fontWeight: 400 }}> , littlenomad.mn хаягуудаар холбоо барих боломжтой.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Little nomad-M kids эрхийн хугацаа дуусахаас өмнө Little nomad-M kids эрхийг цуцлах хүсэлт гаргасан тохиолдолд буцаан олголт
                хийгдэхгүй.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Little nomad-M kids энэхүү үйлчилгээний нөхцөлд нэмэлт өөрчлөлт оруулах бүрэн эрхтэй.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Үйлчилгээ нь таны дансанд гаднын нөлөөтэй аливаа халдлага, алдаа, вирус, хөндлөнгийн оролцоо болон хакер дайралтаас хамгаална гэсэн
                баталгаа өгөхгүй ба үүнтэй холбоотой аливаа асуудалд Little nomad-M kids хариуцлага хүлээхгүй болно.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Little nomad-M kids нь үйлчилгээний нөхцөлд нэмэлт, өөрчлөлт оруулах бүрэн эрхтэй ба хэрэв өөрчлөлт орсон тохиолдолд аппликейшнаар
                дамжуулан хэрэглэгчдэд мэдэгдэл хүргэнэ.
              </span>
            </li>
          </ol>
          <p>
            <br />
            <br />
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Бидэнтэй холбогдох</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Хэрэв танд энэхүү үйлчилгээний нөхцөлтэй холбоотой асуулт байвал бидэнтэй холбогдоно уу.</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>LittleNomad2020@gmail.com болон LittleNomadMagazine фейсбүүк пэйж хуудастай холбогдоно уу.</span>
          </p>
        </div>

        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "40px",
            fontSize: "8px",
            color: "black",
          }}
        >
          © 2024 “ЛИТТЛЕ НОМАД МАГАЗИН“ ХХК
        </span>
      </div>
    </div>
  );
};

export default TermsPage;
