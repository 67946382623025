import React, { useEffect, useState } from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";

const AppRedirectPage = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const appStoreUrl = "https://apps.apple.com/us/app/littlenomad-magazine/id6738403526";
    const playStoreUrl = "https://play.google.com/store/apps/details?id=com.littlenomadmagazine.littlenomad";

    if (isAndroid) {
      // window.location.href = playStoreUrl;
    } else if (isIOS) {
      window.location.href = appStoreUrl;
    } else if (!isMobile) {
      setIsDesktop(true); // User is on a desktop
    } else {
      console.log("User is not on a supported mobile device");
    }
  }, []);

  return (
    <div
      style={{
        background: "url('bg.jpg') no-repeat center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed", // Ensures background stays in place
        display: "flex",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isDesktop ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <h1
            style={{
              color: "#2196f3",
              fontSize: 56,
              fontFamily: "Arial",
              textShadow: `-2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white`,
              lineHeight: 1,
            }}
          >
            Little Nomad-M kids
          </h1>
          <h2
            style={{
              color: "#2196f3",
              fontSize: 16,
              fontFamily: "Arial",
              lineHeight: 1,
              textTransform: "lowercase",
            }}
          >
            Хүүхдийг сэтгүүл
          </h2>

          <div style={{ display: "flex", justifyContent: "center", gap: "20px", paddingTop: "40px" }}>
            <img
              src="mobile.png"
              alt="Download on the App Store"
              style={{
                width: "272px",
                height: "536px",
              }}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "center", gap: "20px", paddingTop: "50px", paddingBottom: "100px" }}>
            <a href="https://apps.apple.com/us/app/littlenomad-magazine/id6738403526" target="_blank" rel="noopener noreferrer">
              <img src="app-store-badge.png" alt="Download on the App Store" width="269" height="80" />
            </a>
            {/* <a href="https://play.google.com/store/apps/details?id=com.littlenomadmagazine.littlenomad" target="_blank" rel="noopener noreferrer">
              <img src="google-play-badge.png" alt="Get it on Google Play" width="269" height="80" />
            </a> */}
          </div>

          <span
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
              fontSize: "8px",
              color: "white",
            }}
          >
            © 2024 “ЛИТТЛЕ НОМАД МАГАЗИН“ ХХК
          </span>
        </div>
      ) : (
        <div style={{ color: "white", textAlign: "center", textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)" }}>
          Хүлээгээрэй, тохирох програмын дэлгүүр рүү чиглүүлж байна...
        </div>
      )}
    </div>
  );
};

export default AppRedirectPage;
