import React, { useEffect, useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ReactLenis } from "@studio-freight/react-lenis";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";

import "./App.css";

import Home from "./pages/Home";
import AppRedirectPage from "./pages/AppRedirectPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";

const imageUrls = [];

const App = () => {
  const lenisRef = useRef();

  const [loadedImages, setLoadedImages] = useState(0);
  const totalImages = imageUrls.length;

  useEffect(() => {
    const preLoadImages = () => {
      let loadedCount = 0;

      const loadImage = (url) => {
        const img = new Image();
        img.src = url;

        img.onload = () => {
          loadedCount++;
          setLoadedImages(loadedCount);
        };
      };

      imageUrls.forEach((url) => {
        loadImage(url);
      });
    };

    preLoadImages();
  }, []);

  return (
    <>
      {loadedImages === totalImages ? (
        <Router>
          <ReactLenis ref={lenisRef} root>
            <AnimatePresence>
              <motion.div initial={{ opacity: 0, x: 0 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 0 }} transition={{ duration: 0.5 }}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/app" element={<AppRedirectPage />} />
                  <Route path="/terms" element={<TermsPage />} />
                  <Route path="/privacy" element={<PrivacyPage />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </motion.div>
            </AnimatePresence>
          </ReactLenis>
        </Router>
      ) : (
        <div className="flex flex-col w-full h-full justify-center items-center fixed">
          <img src="images/logo.png" alt="Miracle - Medical Consulting" className="w-[50px]" style={{ margin: "0 auto 20px" }} />
          <div className="progressWrapper">
            <progress value={loadedImages} max={totalImages}></progress>
          </div>
        </div>
      )}
    </>
  );
};

export default App;
