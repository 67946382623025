import React from "react";

const PrivacyPage = () => {
  return (
    <div
      style={{
        background: "url('bg_stars.jpg') no-repeat center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed", // Ensures background stays in place
        display: "flex",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ textAlign: "", marginTop: "50px" }}>
        <div style={{ maxWidth: "600px", padding: "10px 20px 10px 20px" }}>
          <p>
            <span style={{ fontWeight: 400 }}>Эцэг эх болон асран хамгаалагчдад зориулагдсан болно.</span>
          </p>
          <h1>
            <span style={{ fontWeight: 400 }}>Нууцлалын бодлого</span>
          </h1>
          <p></p>
          <p>
            <span style={{ fontWeight: 400 }}>Сүүлд шинэчлэгдсэн: 2023/11/10</span>
          </p>
          <p></p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Энэхүү нууцлалын бодлого нь "Little nomad-M kids"-г ашиглах үед таны хувийн мэдээллийг хэрхэн удирдахтай холбоотой бидний зүгээс
              баримталж буй бодлогыг тодорхойлоход оршино.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Дараах нөхцөлийг хүлээн зөвшөөрснөөр таны хувийн мэдээллийг цуглуулах, боловсруулах, ашиглах тухай уншиж танилцсаныг тань илэрхийлж буй
              болно.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Хэрэв энэхүү нууцлалын бодлогод тодорхойлсны дагуу хувийн мэдээллийг цуглуулах, боловсруулах, ашиглах нөхцөлийг зөвшөөрөхгүй бол
              үйлчилгээг ашиглахаас татгалзах эрхтэй.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Нэг. Ерөнхий мэдээлэл, мэдээлэл цуглуулах</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Бид дараах зорилгоор таны мэдээллийг бүртгэж авдаг бөгөөд энэ нь танд илүү үр өгөөжтэй, боловсронгуй үйлчилгээг хүргэхэд оршино. Үүнд:
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Хэрэглэгч платформд нэгээс олон удаа мэдээллээ оруулахгүй байхаар ашиглахад хялбар болгох.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Үйлчилгээний чанарыг сайжруулах, хөгжүүлэх зорилгоор.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Хэрэглэгчид хамаарч болохуйц контентыг харуулах.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Хэрэглэгчид шинэ мэдээ мэдээлэл, бүтээгдэхүүн, үйлчилгээг хүргэх.</span>
            </li>
          </ul>
          <ol>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Хувийн мэдээлэл. Little nomad-M kids таны хувийн мэдээллийг зарах, задруулах, ямар нэгэн байдлаар ашиг сонирхлын зорилгоор
                ашигладаггүй болно. Зарим тохиолдолд бүтээгдэхүүн, үйлчилгээгээ сайжруулах зорилгоор гуравдагч этгээдтэй хуваалцаж болно.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Бүртгэл. Үйлчилгээг ашиглахын тулд та утасны дугаараараа бүртгүүлж бүртгэл үүсгэгдсэн тохиолдолд дахин давтагдашгүй хэрэглэгчийн ID
                дугаар үүснэ.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Худалдан авалт. Little nomad-M kids эрх худалдан авсан тохиолдолд худалдан авалтын мэдээлэл буюу хэрэглэгчийн ID дугаар, шилжүүлэг
                хийсэн данс, шилжүүлэг хийсэн хугацаа зэрэг нь системд хадгалагдана. / хэрэглэгчийн худалдан авалтын мэдээллийг гадагш задруулахгүй
                болно. /
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Хэрэглэгч платформыг ашиглах явцад контентын / сонирхсон, үзэж байгаа, үзсэн / мэдээлэл хадгалагдах бөгөөд " жагсаалтаас хасах "
                дарсан тохиолдолд системд бүртгэгдсэн контентын мэдээлэл устана.
              </span>
            </li>
          </ol>
          <p>
            <br />
            <br />
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Хоёр: үйлчилгээ ашиглалтын мэдээлэл</span>
          </p>
          <p></p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Little nomad-M kids платформыг ашиглаж буй үед таны ашиглаж буй хөтчөөс илгээж буй ашиглалтын мэдээллийг дараах зорилгоор цуглуулна.
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Үйлчилгээг хэвийн, тогтвортой үзүүлэх</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Үйлчилгээний хүрээнд хийгдэж буй өөрчлөлтийг мэдэгдэх, мэдээлэх</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Хэрэглэгчдэд тусламж, үйлчилгээ үзүүлэх</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Урамшуулал болон цаг үеийн шинжтэй мэдээллийг хуваалцах</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Үйлчилгээг хөгжүүлэх, сайжруулах зорилгоор үйлчилгээний талаарх дүн шинжилгээ бүхий мэдээллээр хангахад
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Ашиглалтыг хянах, зохицуулах</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Техникийн асуудал, доголдлыг илрүүлэх, тодорхойлох, аливаа асуудлаас урьдчилан сэргийлэх</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Маркетинг болон бизнесийн оновчтой шийдвэр гаргах</span>
            </li>
          </ul>
          <p></p>
          <p>
            <span style={{ fontWeight: 400 }}>Гурав. Өгөгдөл задруулах</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Little nomad-M kids нь таны хувийн мэдээллийг дараах үндэслэлээр хуулийн шаардлагын дагуу зайлшгүй тохиолдолд задруулах боломжтой.
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Хуулиар хүлээсэн үүргээ биелүүлэх</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>ЛИТТЛЕ НОМАД МАГАЗИН ХХК-ийн хууль ёсны эрх, өмчийг хамгаалах</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Үйлчилгээг ашиглахтай холбоотой учирч болзошгүй хууль бус үйлдлээс хамгаалах,урьдчилан сэргийлэх, мөрдөн шалгах
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Нийтийн болон хэрэглэгчдийн хувийн аюулгүй байдлыг хамгаалах</span>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>Дөрөв. Өгөгдөл устгах</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Little nomad-M kids дээр цуглуулсан хэрэглэгчийн хувийн мэдээллүүд нь тухайн хэрэглэгчийн хүсэлтээр устгагдаж болно.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Тав. Гуравдагч этгээдийн бодлого</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Бид үйлчилгээгээ сайжруулах, үйлчилгээ үзүүлэх болон үйлчилгээ ашиглалтад дүн шинжилгээ хийхэд мэргэжлийн дэмжлэг туслалцаа авах, хүний
              нөөц бүрдүүлэх зорилгоор гуравдагч талын байгууллага хувь хүмүүс / хамтад нь үйлчилгээ үзүүлэгчид гэх/ -тэй хамтран ажиллах боломжтой.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Тэдгээр гуравдагч талын этгээдүүд нь зөвхөн бидний нэрийн өмнөөс өгөгдөл, мэдээлэлд хандаж ажил үүрэг гүйцэтгэж ажиллах боломжтой байх
              бөгөөд бусад зорилгоор ашиглахгүй, задруулахгүй байх үүрэг хүлээнэ.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Бид гуравдагч этгээдийн аливаа цахим хуудас, үйлчилгээ, түүнд агуулагдах контент, нууцлалын бодлого, үйлчилгээ үзүүлж буй арга хэлбэрт
              хяналт тавьдаггүй бөгөөд түүнд хариуцлага хүлээхгүй.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Зургаа. Нууцлалын бодлогын өөрчлөлт</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Бид энэхүү нууцлалын бодлогыг ямар ч үед өөрчлөх эрхтэй.Хэрэв энэхүү нууцлалын бодлогод нэмэлт өөрчлөлт орсон тохиолдолд танд мэдэгдэл
              очих бөгөөд хүчин төгөлдөр болохоос өмнө платформд нийтлэх замаар нийтэд хүргэнэ.Нууцлалын бодлогын талаарх хамгийн сүүлийн мэдээлэлтэй
              танилцахын тулд нууцлалын бодлого цэсийг үе үе шалгаж байхыг зөвлөж байна. Энэхүү нууцлалын бодлогод нэмэлт өөрчлөлт оруулсны дараа
              үйлчилгээг үргэлжлүүлэн ашигласан бол өөрчлөлтийг хүлээн зөвшөөрч байгаа гэж үзнэ.
            </span>
          </p>
          <p>
            <br />
            <br />
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Бидэнтэй холбогдох</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Хэрэв танд энэхүү нууцлалын бодлоготой асуулт байвал бидэнтэй холбогдоно уу.</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>LittleNomad2020@gmail.com болон LittleNomadMagazine фейсбүүк пэйж хуудастай холбогдоно уу.</span>
            <br />
            <br />
          </p>
        </div>

        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "40px",
            fontSize: "8px",
            color: "black",
          }}
        >
          © 2024 “ЛИТТЛЕ НОМАД МАГАЗИН“ ХХК
        </span>
      </div>
    </div>
  );
};

export default PrivacyPage;
